import React from 'react'
import Ticker from 'react-ticker'


const quotes = [
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
    'Big Sean, Philanthropist and a Gentleman!!',
    'The hottest MC in the game is from BUFFALO?!',
    'Sean Evans Reveals His Favorite Big Sean Song!',
    'Truth or Dab The Game Available Now!',
  ]

const links = [
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
    'https://www.seanandersonfoundation.org/',
    'https://twitter.com/firstwefeast/status/1189935062692831233',
    'https://www.youtube.com/watch?v=oak7VyEHvro',
    'https://truthordabgame.com/',
 ]

const NewsTicker = () => (

        
    <Ticker 
    offset="25%"
    speed={4}
    >
        {({ index }) => (
            <>
            <a className="ticker_text" target="_blank" style={{"text-decoration": "none"}} onClick={()=>{window.trackEvent(`click ${quotes[index]}`)}} href={links[index]}> {quotes[index]}</a>
            </>
        )}
    </Ticker>
)
 
export default NewsTicker


// <a className="ticker_text" style={{"text-decoration": "none"}} href="https://www.seanandersonfoundation.org/">Big Sean, Philanthropist and a Gentleman!!</a> 
// <a className="ticker_text" style={{"text-decoration": "none", "color":"black"}} href="https://twitter.com/firstwefeast/status/1189935062692831233">The hottest MC in the game is from BUFFALO?!</a> 
// <a className="ticker_text" style={{"text-decoration": "none", "color":"black"}} href="https://www.youtube.com/watch?v=oak7VyEHvro">Sean Evans Reveals His Favorite Big Sean Song!</a>
