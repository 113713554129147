import React, {useEffect} from 'react';
import NewsTicker from '../NewsTicker/NewsTicker.js';
import './MainContainer.css';


function MainContainer() {

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://apis.google.com/js/platform.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        }
      }, []);


  return (
    <div id="MainContainer">
        <div id="ticker_container">
            <NewsTicker className="ticker"/>
        </div>
        <div id="header">
            <img className="seansean_logo" src="/microsite-seansean/img/seanxsean.gif" alt="seansean logo gif" />
        </div>
        <div id="video">
            <div className="video" style={{position: "relative", paddingBottom: "56.25%", paddingTop: 25, height: 0}}>
                <iframe title="seansean video" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }} src="https://www.youtube.com/embed/4VoqwrEEAZM" frameBorder="0" />
            </div>
        </div>
        <div className="gif_gallery_header">Download the hottest Sean GIFs</div>
        <div id="gif_gallery">
            <div className="gif_row">
                <a className="tag" onClick={()=>{window.trackEvent(`gif1`)}} target="_blank" href="/microsite-seansean/img/gifs/gif1.gif"><img className="gif" src="/microsite-seansean/img/gifs/gif1.gif" alt="sean evans gif"/></a>
                <a className="tag" onClick={()=>{window.trackEvent(`gif2`)}} target="_blank" href="/microsite-seansean/img/gifs/gif2.gif"><img className="gif" src="/microsite-seansean/img/gifs/gif2.gif" alt="sean evans gif"/></a>

            </div>
            <div className="gif_row">
                <a className="tag" onClick={()=>{window.trackEvent(`gif3`)}} target="_blank" href="/microsite-seansean/img/gifs/gif3.gif"><img className="gif" src="/microsite-seansean/img/gifs/gif3.gif" alt="sean evans gif"/></a>
                <a className="tag" onClick={()=>{window.trackEvent(`gif4`)}} target="_blank" href="/microsite-seansean/img/gifs/gif4.gif"><img className="gif" src="/microsite-seansean/img/gifs/gif4.gif" alt="sean evans gif"/></a>

            </div>
            <div className="gif_row">
                <a className="tag" onClick={()=>{window.trackEvent(`gif5`)}} target="_blank" href="/microsite-seansean/img/gifs/gif5.gif"><img className="gif" src="/microsite-seansean/img/gifs/gif5.gif" alt="sean evans gif"/></a>
                <a className="tag" onClick={()=>{window.trackEvent(`gif6`)}} target="_blank" href="/microsite-seansean/img/gifs/gif6.gif"><img className="gif" src="/microsite-seansean/img/gifs/gif6.gif" alt="sean evans gif"/></a>
            </div>
        </div>
        <div id="subscribe_cta">
            <div className="cta_wrap">
                <script src="https://apis.google.com/js/platform.js"></script>
                <div className="g-ytsubscribe" data-channel="firstwefeast" data-layout="full" data-count="default"></div>
            </div>
        </div>
        <div id="flames"></div>
    </div>
  );
}

export default MainContainer;
